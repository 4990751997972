/* global
  Cookies
 */
$(document).ready(function (){
  placeAffiliateCookie();
});

function grabAffiliateFromParam (name) {
  var url = window.location.href;
  var results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(url);
  if (!results) {
    return undefined;
  }
  return results[1] || undefined;
}

function placeAffiliateCookie () {
  var affiliate = grabAffiliateFromParam('afmc');
  if (affiliate){

    var inSixtyDays = new Date();
    inSixtyDays.setTime(inSixtyDays.getTime() + 5184000000);
    var toStringify = {
      affiliate: affiliate
    };
    Cookies.set('NUMUNDO_TRACKING', JSON.stringify(toStringify), {expires: inSixtyDays});

  }
}

